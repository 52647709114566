



























import { Component, Vue } from "vue-property-decorator";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar, { EventClickArg } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import BillService from "@/services/BillService";
import { Calendar } from "@/models";

@Component({ components: { FullCalendar } })
export default class CalendarPage extends Vue {
  private calendarOptions: any = {
    plugins: [dayGridPlugin],
    initialView: "dayGridMonth",
    locale: "vie",
    height: "auto",
    headerToolbar: {
      start: "title", // will normally be on the left. if RTL, will be on the right
      center: "",
      end: "today prevYear,prev,next,nextYear" // will normally be on the right. if RTL, will be on the left
    },
    buttonText: { today: "Hôm nay" },
    events: [],
    eventClick: this.handleEventClick
  };
  private contractId: string = "";
  private billId: string = "";

  created() {
    this.getAllCalendar();
  }

  getAllCalendar() {
    BillService.getAllCalendar().then(res => {
      if (res.status === 200) {
        let events: any[] = [];
        res.data.forEach((cal: Calendar) => {
          let event: any = {};
          event.id = cal.contractId;
          event.title = cal.contractCode;
          event.backgroundColor = cal.color;
          event.borderColor = cal.color;
          event.start = this.$moment(cal.time, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          );
          event.end = this.$moment(cal.time, "DD-MM-YYYY").format("YYYY-MM-DD");
          event.extendedProps = {
            billId: cal.billId
          };
          events.push(event);
        });
        this.calendarOptions.events = events;
      }
    });
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (clickInfo.event.id) {
      this.contractId = clickInfo.event.id;
      this.billId = clickInfo.event.extendedProps.billId;
      this.$bvModal.show("modal-contract-detail");
    }
  }
}
